body {
  cursor: url('./assets/cursor.png'), auto;
}

@font-face {
  font-family: chicago;
  src: url(./assets/fonts/Chicago.ttf);
}

@font-face {
  font-family: parcplace;
  src: url(./assets/fonts/Parc_Place.ttf);
}

@font-face {
  font-family: fairfax;
  src: url(./assets/fonts/FairfaxHD.ttf);
}

body {
  margin: 0;
  font-family: chicago, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a:visited {
  color: magenta;
}
